<template>
  <div class="related-product-container">
    <section
      class="container cl-black p0-mobile"
      v-if="product.related[type] && product.related[type].length > 0"
    >
      <div class="row m0 padding-related">
        <div class="col-md-8 media-padding">
          <div class="row m0 shadow-hover-elemet">
            <header class="col-xs-12 p0">
              <h2 class="m0 cl-white weight-400 bg-cl-alternative text-sizes align-left pl20 py5">
                <template>
                  {{ heading }}
                </template>
              </h2>
            </header>
            <div class="col-xs-12 p0">
              <products-carousel :is-not-show-hover="true" :products="product.related[type]" />
            </div>
          </div>
        </div>
        <div class="col-md-4" />
      </div>
    </section>
  </div>
</template>

<script>
import ProductsCarousel from 'theme/components/core/ProductsCarousel.vue'
// import ProductListing from 'theme/components/core/ProductListing'
import { mapGetters } from 'vuex'
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'
import i18n from '@vue-storefront/i18n'
import config from 'config'

export default {
  name: 'Related',
  props: {
    type: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: false,
      default: i18n.t('Similar products')
    }
  },
  data () {
    return {
      isEmpty: false
    }
  },
  components: {
    // ProductListing,
    ProductsCarousel
  },
  beforeMount () {
    this.$bus.$on('product-after-load', this.refreshList)
    if (config.usePriceTiers) {
      this.$bus.$on('user-after-loggedin', this.refreshList)
      this.$bus.$on('user-after-logout', this.refreshList)
    }

    this.refreshList()
  },
  beforeDestroy () {
    if (config.usePriceTiers) {
      this.$bus.$off('user-after-loggedin', this.refreshList)
      this.$bus.$off('user-after-logout', this.refreshList)
    }
  },
  destroyed () {
    this.$bus.$off('product-after-load', this.refreshList)
  },
  methods: {
    refreshList () {
      let sku = this.productLinks ? this.productLinks
        .filter(pl => pl.link_type === this.type)
        .map(pl => pl.linked_product_sku) : null
      let key = 'sku'
      let relatedProductsQuery = prepareRelatedQuery(key, sku)

      this.$store.dispatch('product/list', {
        query: relatedProductsQuery,
        size: 20,
        prefetchGroupProducts: false,
        updateState: false,
        includeFields: config.entities.productUpsell.includeFields,
        excludeFields: config.entities.productUpsell.excludeFields
      }).then((response) => {
        if (response) {
          let resItems = response.items.filter(item => { return item.sku !== this.product.current.parentSku })
          if (resItems.length > 4) {
            this.$store.dispatch('product/related', {
              key: this.type,
              items: resItems
            })
          } else {
            this.isEmpty = true
            let lastCategory = this.getBreadcrumbsRoutes.slice(-1).pop()
            if (lastCategory && lastCategory.id) {
              sku = [lastCategory.id]
            } else {
              sku = this.product.current.category.map(cat => cat.category_id)
            }
            relatedProductsQuery = prepareRelatedQuery('category_ids', sku)
            this.$store.dispatch('product/list', {
              query: relatedProductsQuery,
              size: 20,
              prefetchGroupProducts: false,
              updateState: false,
              includeFields: config.entities.productUpsell.includeFields,
              excludeFields: config.entities.productUpsell.excludeFields
            }).then((response) => {
              if (response) {
                this.$store.dispatch('product/related', {
                  key: this.type,
                  items: [...resItems, ...response.items]
                })
                this.$forceUpdate()
              }
            })
          }
          this.$forceUpdate()
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      getCurrentProduct: 'product/getCurrentProduct',
      getBreadcrumbsRoutes: 'breadcrumbs/getBreadcrumbsRoutes'
    }),
    product () {
      return this.$store.state.product
    },
    productLinks () {
      return this.getCurrentProduct.product_links
    }
  }
}
</script>

<style lang="scss" scoped>
.media-padding {
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis: 100%;
    max-width: 100%;
  }
  @media (max-width: 991px) {
    padding: 0px;
  }
}
.padding-related {
  padding: 8px 0;
  @media (max-width: 767px) {
     padding: 0;
  }
}
.p0-mobile {
   @media (max-width: 767px) {
     padding: 0;
  }
}
.related-product-container {
  margin-top: 30px;
  padding: 0 20px;
  @media (max-width: 991px) {
    padding: 0;
  }
  @media (max-width: 767px) {
    margin-top: 25px;
    padding: 0;
  }
}
.shadow-hover-elemet {
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  @media (max-width: 767px) {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}
.text-sizes {
  font-size: 20px;
  text-transform: uppercase;
   @media (max-width: 767px) {
     font-size: 18px;
     text-transform: none;
   }
}
</style>
